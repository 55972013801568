import { TwitterOutlined } from "@ant-design/icons";
import { Col, Row, Space, Typography } from "antd";
import Container from "components/Common/Container";
import Logo from "components/Common/Logo";
import { isMobile } from "mobile-device-detect";
import Link from "next/link";
import React from "react";
import { BsInstagram, BsLinkedin } from "react-icons/bs";
import { Analytics } from "utils/Analytics/AnalyticsHelper";

const { Text } = Typography;

const PublicFooter: React.FC = () => {
  const currentYear = new Date().getFullYear() || "2022";
  return (
    <Container type="fluid" className="footer">
      <Container>
        <Row justify="center">
          <Col xs={{ span: 22, offset: 0 }} md={{ span: 24, offset: 0 }}>
            <Row justify="space-between">
              <Col
                xs={{ span: 24, offset: 0 }}
                md={{ span: 16, offset: 0 }}
                className="footer-logo"
              >
                <Logo width={isMobile ? 203 : 500} height={40} theme="dark" />
              </Col>
              <Col xs={{ span: 24, offset: 0 }} md={{ span: 8, offset: 0 }}>
                <Row>
                  <Col xs={{ span: 12, offset: 0 }}>
                    <Space size={"large"} direction="vertical" align="start">
                      <Link href="/about" className="footer-link">
                        About
                      </Link>
                      <Link href="/contact" className="footer-link">
                        Contact Us
                      </Link>
                      <Link href="/terms" className="footer-link">
                        Terms Of Service
                      </Link>
                      <Link href="/privacy" className="footer-link">
                        Privacy
                      </Link>
                    </Space>
                  </Col>
                  <Col xs={{ span: 12, offset: 0 }}>
                    <Space size={"large"} direction="vertical" align="start">
                      <Link href="/pricing" className="footer-link">
                        Pricing
                      </Link>
                      <Link
                        href={"https://topmate.click/blog"}
                        target="_blank"
                        onClick={() =>
                          Analytics.track("Blog Clicked", {
                            source: "Footer",
                          })
                        }
                        className="footer-link"
                      >
                        Blog
                      </Link>
                      <a
                        href="https://topmate-production.s3.ap-south-1.amazonaws.com/Creator_Report_2022_Topmate.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-link"
                        onClick={(): void =>
                          Analytics.track("Footer Link Clicked", {
                            Type: "Creator Economy Report",
                          })
                        }
                      >
                        Creator report 2022
                      </a>
                      <a
                        href="https://carouselmaker.io/?utm_source=Topmatet&utm_medium=Click&ref=topmate"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-link"
                        onClick={(): void =>
                          Analytics.track("Footer Link Clicked", {
                            Type: "Carousel Maker",
                          })
                        }
                      >
                        Carousel Maker
                      </a>
                    </Space>
                  </Col>
                </Row>
                <Col span={24} style={{ paddingTop: 48 }}></Col>
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="footer-nav">
              <Col
                xs={{ span: 24, offset: 0, order: 2 }}
                md={{ span: 16, offset: 0, order: 1 }}
              >
                <Space direction="vertical" size={16}>
                  <Text className="footer-link">
                    548 Market St PMB 30073, San Francisco
                  </Text>
                  <Text className="footer-link">
                    &copy;{currentYear} Topmate
                  </Text>
                </Space>
              </Col>
              <Col
                xs={{ span: 24, offset: 0, order: 1 }}
                md={{ span: 8, offset: 0, order: 2 }}
                className="footer-socials"
              >
                <Space size={40}>
                  <a
                    href="https://www.linkedin.com/company/topmate-io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-linkedin"
                    onClick={(): void =>
                      Analytics.track("socialpage_clicked", {
                        Type: "Linkedin",
                      })
                    }
                  >
                    <BsLinkedin size={32} />
                  </a>
                  <a
                    href="https://mobile.twitter.com/topmatehq"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-linkedin"
                    onClick={(): void =>
                      Analytics.track("socialpage_clicked", { Type: "Twitter" })
                    }
                  >
                    {/* <BsTwitter size={32} /> */}
                    <TwitterOutlined size={32} />
                  </a>
                  <a
                    href="https://www.instagram.com/topmate.io/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-linkedin"
                    onClick={(): void =>
                      Analytics.track("socialpage_clicked", {
                        Type: "Instagram",
                      })
                    }
                  >
                    <BsInstagram size={32} />
                  </a>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default PublicFooter;
