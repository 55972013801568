// import Image from "next/image";
import TImage from "components/Common/TImage";
import Link from "next/link";
import { CSSProperties } from "react";

interface ILogo {
  imageStyle?: CSSProperties;
  width?: number | `${number}`;
  height?: number;
  link?: string;
  theme?: "light" | "dark" | "custom" | undefined;
  logo?: boolean;
  className?: string;
}

const Logo: React.FC<ILogo> = ({
  width = 158,
  height = 29,
  imageStyle = {},
  link = "/",
  theme = "light",
  logo = false,
  className,
  ...rest
}) => (
  <Link href={link} className="logo" {...rest}>
    <TImage
      src={
        logo
          ? "/images/common/logo-icon.svg"
          : theme === "dark"
          ? "/images/common/topmate-dark.svg"
          : "/images/common/topmate-light.svg"
      }
      styles={imageStyle}
      alt="Topmate Logo"
      width={width}
      height={height}
      className={className}
    />
  </Link>
);

export default Logo;
