import { Button, Menu, MenuProps, Space } from "antd";
import TImage from "components/Common/TImage";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import PricingImage from "public/images/homepage/discount-tags.svg";
import AboutImage from "public/images/homepage/information.svg";
import CreatorImage from "public/images/homepage/office-bag.svg";
import LoginImage from "public/images/homepage/thumbprint.svg";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaChevronDown, FaSearch } from "react-icons/fa";
import { RiProductHuntLine } from "react-icons/ri";
import { RxTextAlignJustify } from "react-icons/rx";
import { useCategoriesUseCase } from "services/ApiHooks";
import { Analytics } from "utils/Analytics/AnalyticsHelper";
import config from "utils/config";
import { loginRedirect } from "../PublicLayout";

const MobileSignupNav = dynamic(() => import("./MobileSignupNav"), {
  ssr: false,
});

interface IMenuProps {
  theme?: "light" | "dark" | "custom" | undefined;
}

const BurgermenuExternal: React.FC<IMenuProps> = ({ theme }) => {
  const { categories, isLoading } = useCategoriesUseCase(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const router = useRouter();
  const onClick: MenuProps["onClick"] = (e) => {
    if (e?.key === "pricing") {
      router.push(`${config.BASE_URL}${e?.key}`);
    } else if (e.key === "podcast") {
      return;
    } else if (e.key === "earnings") {
      router.push(`#earnings`);
      setMenuOpen(false);
      Analytics.track("NavClicked", {
        Type: "Earnings Calculator",
        Device: "Mobile",
      });
    } else if (e?.key === "login") {
      loginRedirect();
    } else {
      router.push(`/for/${e?.key}`);
    }
  };

  const menuClicked = (e) => {
    if (e?.length == 0 || (e[0] == "4" && e?.length === 1)) {
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "",
      key: "",
      icon: null,
      expandIcon: menuOpen ? <AiOutlineClose /> : <RxTextAlignJustify />,
      children: [
        {
          key: "login",
          label: (
            <Link
              href={loginRedirect()}
              onClick={(): void => Analytics.track("login_requested")}
            >
              Login
            </Link>
          ),
          icon: <TImage src={LoginImage} alt="Icon" width={30} height={30} />,
        },
        {
          key: "podcast",
          label: (
            <Link
              href={"https://topmate.click/goto_podcast"}
              target="_blank"
              onClick={() =>
                Analytics.track("Podcast Clicked", {
                  page: "Homepage",
                  source: "Mobile Nav",
                })
              }
            >
              Podcast
            </Link>
          ),
          icon: <TImage src={AboutImage} alt="Icon" width={30} height={30} />,
        },
        {
          key: "pricing",
          label: <Link href={`${config.BASE_URL}pricing`}>Pricing</Link>,
          icon: <TImage src={PricingImage} alt="Icon" width={30} height={30} />,
        },
        {
          key: "earnings",
          label: (
            <a
              href={`${config.BASE_URL}#earnings`}
              className={
                theme == "dark"
                  ? "public-menu-link btn dark-menu-link"
                  : "public-menu-link btn"
              }
            >
              Earnings calculator
            </a>
          ),
        },
        {
          key: "4",
          label: "For Creators",
          icon: <TImage src={CreatorImage} alt="Icon" width={30} height={30} />,
          expandIcon: <FaChevronDown />,
          children:
            !isLoading &&
            categories?.map((category) => {
              return {
                label: category?.name,
                key: category?.slug,
                icon: category?.icon ? (
                  <TImage
                    src={category?.icon}
                    alt={category?.name || "Icon"}
                    width={30}
                    height={30}
                  />
                ) : (
                  <RiProductHuntLine />
                ),
              };
            }),
        },
      ],
    },
  ];

  return (
    <Space direction="horizontal" style={{ display: "flex" }}>
      <Link href={`https://topmate.click/hitonav`} target="_blank">
        <Button
          className={"ai-search-button"}
          onClick={() => {
            Analytics.track("NavClicked", {
              Type: "AI Search",
              Device: "Mobile",
            });
          }}
        >
          <FaSearch size={18} />
        </Button>
      </Link>
      <MobileSignupNav />

      <Menu
        onClick={onClick}
        onOpenChange={menuClicked}
        mode="inline"
        className="nav-submenu"
        items={items}
        triggerSubMenuAction="click"
      />
    </Space>
  );
};

export default BurgermenuExternal;
