export const URLConstants = {
  // Public
  SIGN_IN: "/sign-in",
  SIGN_UP: "/signup",
  HOME: "/",

  // Onboarding

  //   Dashboard
  DASHBOARD_HOME: "/dashboard/home",
};
