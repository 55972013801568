import React, { CSSProperties } from "react";

interface IContainer {
  className?: string;
  type?: "fluid";
  children: React.ReactNode;
  id?: string;
  style?: CSSProperties;
}

const Container: React.FC<IContainer> = (props: IContainer) => {
  const { children, className, type, id, style } = props;
  const getClassNames = (): string => {
    let classes = "";
    if (type === "fluid") {
      classes = classes + "container-fluid ";
    } else {
      classes = classes + "container ";
    }
    if (className) {
      classes += className;
    }
    return classes;
  };
  return (
    <div className={getClassNames()} id={id} style={style}>
      {children}
    </div>
  );
};

export default Container;
