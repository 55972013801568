import dynamic from "next/dynamic";
import React, { CSSProperties } from "react";

const Lottie = dynamic(() => import("react-lottie-player"), { ssr: false });

interface ILottieProps {
  src: object;
  loop?: boolean;
  play?: boolean;
  style?: CSSProperties;
  block?: boolean;
  className?: string;
}

const LottieComponent: React.FC<ILottieProps> = (props: ILottieProps) => {
  const { loop, src, play, style, block, className } = props;
  const BlockRenderingSettings = { preserveAspectRatio: "xMidYMid slice" };
  return (
    <Lottie
      loop={loop}
      play={play}
      style={style}
      animationData={src}
      rendererSettings={block ? BlockRenderingSettings : undefined}
      renderer="svg"
      className={className}
    />
  );
};

export default LottieComponent;
